.socialMediaButtonsContainer{

    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.socialMediaButton{
    flex: 1;
    width: 50px;
    height: 50px;
    border: none;
    background:none ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin: 5px ;
    
    cursor: pointer;
    border-radius: 5px;
}

