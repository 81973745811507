
.contact{
    background: rgb(236, 236, 236);
}

.contactContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 600px));
    grid-template-rows: repeat(auto-fit, minmax(550px , 1fr));
    gap:25px 10%;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: 100px;
}


.contact .titleContact {
    margin:0;
    padding: 25px ;

    text-align: center;
}



@media screen and (max-width: 700px) {
    
    .contact h1{
        margin: 15px;
        
    }
    
    .contactContainer {
        grid-template-columns: repeat(auto-fit, 100%);
        grid-template-rows: repeat(auto-fit, minmax(550px , 1fr));
    }
    
}
