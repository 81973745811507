
.contactMapContainer{
    height: 100%;
    width: 100%;
}

.contactMap{
    margin: 15px;
    border: solid 2px var(--main-color);
    border-radius: 30px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    height: 575px;

    overflow: hidden;

}

.map{
    height: 100%;
    width: 100%;
}


@media screen and (max-width: 700px) {

    .contactMapContainer h2{
        margin: 15px;
    }
}