.button-Appointment {
    text-decoration: none;
    background: #fff;
    border: none;
    border: solid 1px var(--main-color);
    border-radius: 10px;
    height: 50px;
    width: 150px;
    margin: 5px;
    font-size: 1.2rem;
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    color: var(--main-color);
    cursor: pointer;

    display: grid;
    place-content: center;

}

.button-Appointment:hover {
    background-color: #f3a8dd58;
}