.slider-container {
    margin: 0px 0px 100px 0px;
    width: auto;
    min-height: 150px;
    max-height: 40vw;
    overflow: hidden;
    position: relative;

    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
    border: 2px solid var(--main-color);
    border-width: 0px 0px 2px;
}

/*Arrows*/

.slider-container .rightArrow,
.slider-container .leftArrow {
    border-style: solid;
    background: none;
    position: absolute;
    z-index: 10;
    width: 4vw;
    height: 4vw;

    bottom: 50%;
    margin-left: 15px;
    margin-right: 15px;

    cursor: pointer;
}

.slider-container .rightArrow {
    right: 0;
    border: none;
    background: no-repeat url('ArrowStart.svg');
    background-size: cover;
}

.slider-container .leftArrow {
    border: none;
    background: no-repeat url('ArrowStart.svg');
    background-size: cover;
    transform: rotate(-180deg);

}

.slider-container .rightArrow:hover {
    background-image: url('ArrowHover.svg');

}

.slider-container .leftArrow:hover {
    background-image: url('ArrowHover.svg');

}

/*images*/
.slider-content {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition-duration: 0.4s;
}

.slider-content img {

    width: 100%;
    min-height: 150px;
    max-height: 40vw;
    display: inline-block;
    /* object-fit: fill; */
    object-fit: cover;

}

.slider-content img:first-child {

    object-fit: contain;

}

@media screen and (max-width: 700px) {

    .slider-container {
        margin: 15px;
        margin-top: 0;
        min-height: 250px;
    }

    /*Arrows*/
    .slider-container .rightArrow,
    .slider-container .leftArrow {
        margin-left: 5px;
        margin-right: 5px;
        bottom: 50%;
        width: 8vw;
        height: 8vw;

    }


    /*images*/
    .slider-content img {
        min-height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .slider-container {
        width: 100%;
        margin: 0;
        border-width: 1px 0px;
    }

    /*Arrows*/
    .slider-container .rightArrow,
    .slider-container .leftArrow {
        width: 10vw;
        height: 10vw;

    }

}

/*
*{
    background: #000 !important;
    border: 0.005vw solid rgb(0, 235, 31) !important ;
}

*/