@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&family=Dancing+Script:wght@700&display=swap');
:root {
    --main-color: #F58ED6;
}

.services h1{
    margin: 50px;
    text-align: center;
}

.cards-container {
    overflow: visible;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(auto-fill, 325px);
    grid-template-rows: repeat(auto-fill, minmax(530px, 1fr));
    gap: 50px;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 25px;
    
    margin-bottom: 100px;
}



@media screen and (max-width: 700px) {
    .services h1{
        margin: 15px;
    }
    
}