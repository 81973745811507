* {
    scroll-behavior: smooth;
    scroll-margin-top: 50px;
}

/*Remove blue highlight */

button, a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root {
    --main-color: #F58ED6;
}

.header {
    position: -webkit-sticky;
    position: sticky;
    background-size: 100%;
    background-color: var(--main-color);
    top: 0;
    width: 100%;
    height: 46px;
    overflow: hidden;
    z-index: 100;
}

.header .responsiveMenu{
    visibility: hidden;
}
/*logo with white background*/

.header .whiteDiv {
    position: absolute;
    z-index: 115;
    display: inline-block;
    background-color: rgb(255, 255, 255);
    clip-path: ellipse(100% 125% at 0% 100%);
    height: 100%;
    width: 50%;
}

#logoHeader {
    z-index: 120;
    position: absolute;
    left: 0px;
    width: 90px;
    height: 45px;
    margin-left: 50px;
}

.nav-container {
    position: absolute;
    right: 0;
    z-index: 115;
    display: block;
    height: 100%;
}

.nav-container a {
    width: 125px;
    height: 100%;
    font-family: 'Roboto', sans-serif, cursive;
    font-size: 17px;
    font-weight: 500;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 3px;
    margin-bottom: 3px;
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.header .nav-container a:nth-child(1) {

    margin-right: 50px;
}

.header .nav-container a:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.247) 0%, rgba(255, 255, 255, 0) 100%);
}

/*Button*/

.nav-container .barMenu-btn-Appointment {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid var(--main-color);
    margin-right: 15px;
    margin-left: 15px;
    color: var(--main-color);
    height: 40px;
    display: grid;
    place-items: center;
}


.nav-container .barMenu-btn-Appointment:hover {
    border: 1px solid #fff;
    background: var(--main-color);
    color: #fff;
}

@media screen and (max-width: 700px) {
    .header .nav-container {
        visibility: hidden;
    }
    .header .responsiveMenu{
        visibility: visible;
    }
    .header #logoHeader {
        margin-left: 15px;
    }
   
    .header .responsiveMenu .buttons {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 115;
        margin-right: 15px;
        height: min-content;
        display: grid;
        grid-template-columns: min-content 25%;
        grid-template-rows: 45px;
        justify-content: center;
        align-content: center;
        justify-items: center;
        align-items: center;
        overflow: hidden;
    }
    .responsiveMenu .btnResponsiveMenu {
        width: 50px;
        height: 70%;
        z-index: 110;
        border: none;
        background: no-repeat center;
        background-size: contain;
        cursor: pointer;
    }
    /*Button */
    .responsiveMenu .barMenu-btn-Appointment {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        margin: 0 5px 0 0;
        height: 40px;
        font-family: 'Roboto',sans-serif;
        font-weight: normal;
        display: grid;
        place-items: center;
    }
    .responsiveMenu .barMenu-btn-Appointment:hover {
        color: #fff;
        border: 2px solid #fff;
        background-color: var(--main-color);
    }
    .header {
        overflow: visible;
    }
    /*Menu Options*/
    .responsiveMenu .nav-container-responsive {
        visibility: hidden;
        position: absolute;
        z-index: 120;
        right: 0%;
        top: 45px;
        width: 100%;
        height: min-content;
        overflow: hidden;
        border-radius: 0 0 20px 20px;
    }
    .responsiveMenu .nav-container-responsive ul {
        background: var(--main-color);
        position: relative;
        list-style: none;
        padding: 0;
        width: 100%;
        height: auto;
        margin: 0;
        margin-left: 2.5%;
        right: -100%;
        animation-iteration-count: 1;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
    .responsiveMenu .nav-container-responsive ul li {
        border-bottom: 1px solid #fff;
    }
    .responsiveMenu .nav-container-responsive a {
        display: block;
        font-size:1.7rem;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        padding: 10px;
        color: #fff;
    }
    .responsiveMenu .nav-container-responsive a:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    }
    .responsiveMenu .nav-container-responsive a:last-child {
        border-bottom: none;
    }
    /*Animation to show and hidde options*/
    @keyframes showMenu {
        from {
            right: -100%;
        }
        to {
            right: 2.5%;
        }
    }
    @keyframes hiddeMenu {
        from {
            right: 2.5%;
        }
        to {
            right: -100%;
        }
    }
}